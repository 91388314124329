import React from "react"
import PropTypes from "prop-types"
import BenefitsTemplate from "templates/BenefitsTemplate"

const BenefitsPage = ({ location }) => (
  <BenefitsTemplate location={location} lang="EN" />
)

BenefitsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default BenefitsPage
